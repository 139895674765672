import React from "react"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import SEO from "../../components/seo"

import PARTNERSHIPS1 from "../../images/partnerships/partnerships-1.jpg"
import PARTNERSHIPS3 from "../../images/partnerships/partner3.jpg"
import PARTNERSHIPS4 from "../../images/partnerships/partner4.jpg"

import AboutSidebar from "../../components/layout/about-sidebar"

const PartnershipsPage = () => {
  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`Professional Partnerships & Trade Shows | Walpole Outdoors`}
        description={`Walpole Outdoors partners with many architects, interior designers, and contractors to deliver exceptional service. Find shows we are a part of.`}
      />

      {/* CONTENT GOES HERE */}
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/about">About</Breadcrumb.Item>
          <Breadcrumb.Item href="/about/partnerships">
            Partnerships
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row className="generalcontent">
          <Col md={3}>
            <AboutSidebar></AboutSidebar>
          </Col>
          <Col md={9}>
            <h1 className="generalheader">Partnerships</h1>
            <img className="imgright" src={PARTNERSHIPS1} />
            <p>
              <strong>
                For eighty years, Walpole has worked closely with professionals
                to help them better serve their customers. We provide expert,
                personal service to architects, landscape architects, interior
                designers, custom home builders, developers, contractors,
                purchasing agents, facilities engineers, tourist attraction and
                parks, and golf course management. Our in-house expertise and
                experience allows us to assist you with drawings and to follow
                through on your design requirements. Volume discounts are
                available on hand crafted items such as lantern posts and mail
                posts, benches, arbors, trellis, gates, themed fence and more.
                For complete details, please call us at <br />
                866-758-7924.
              </strong>
            </p>

            <hr />
            <div className="partner-container">
              <img className="imgleft" src={PARTNERSHIPS3} />
              <h3 className="sectionheading">ASLA</h3>
              <p>
                Walpole is proud to be a member of ASLA and have worked with
                Landscape Architects nationwide. Our services include supplying
                standard products, design support with CAD drawings, and custom
                manufacturing.
              </p>
            </div>
            <div className="partner-container">
              <img className="imgleft" src={PARTNERSHIPS4} />
              <h3 className="sectionheading">NAHB & PCBC</h3>
              <p>
                To enhance the aesthetics and charm of new homes and
                developments, professional Builders often turn to Walpole for
                quality entranceways and signage, cupolas and weathervanes, mail
                posts and mailboxes, lantern posts and lanterns, privacy panels
                and much more.
              </p>
            </div>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default PartnershipsPage
